import { useState } from 'react';

import {
	Box,
	Container,
	FormControl,
	Grid,
	Icon,
	MenuItem,
	MenuProps,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { visuallyHidden } from '@mui/utils';

import {
	App,
	Page,
	Maybe,
	AdFooterSocialNetworksCollection,
	AdFooterStickyFooterButtonsCollection,
	Button,
	AnalyticsContext,
} from '@/types/generated';
import RichText, { RichTextContent } from '@/components/RichText';
import BreadCrumbs from '@/components/BreadCrumbs';
import { useAppContext } from '@/context';
import ChatBot from '@/components/Chatbot';
import { REASONS_FOR_VISIT, SCHEDULE_PATH } from '@/constants';

import TAGLink from '../TAGLink';
import ContentfulButton from '../ContentfulButton';
import { SocialNetworkIcons } from '../FooterAlt/FooterAltSocialLinks';
import TAGSvgIcon from '../TAGSvgIcon/TAGSvgIcon';

import {
	getContentWrapperStyles,
	getContactInfoWrapperStyles,
	copyrightStyles,
	getWrapperStyles,
	backToTopBoxWrapperStyles,
	breadCrumbsContainerStyles,
	footerSocialMediaLinksStyles,
	getAboutWrapperStyles,
	breadCrumbsAndTopBtnContainerStyles,
	backToTopLinkWrapperStyle,
	stickyMobileFooterWrapperStyles,
	stickyDesktopFooterWrapperStyles,
	stickyDesktopFooterBtnsWrapperStyles,
	getReasonSelectStyles,
	reasonMenuItemStyles,
	reasonSelectedMenuItemStyles,
} from './Footer.styles';
import FooterNavigationContent from './FooterNavigationContent';
import FooterPolicy from './FooterPolicy';

interface IStickyFooterButtons {
	stickyFooterButtonsCollection?: Maybe<AdFooterStickyFooterButtonsCollection>;
	logoUrl?: Maybe<string>;
	chatBotUrl?: string;
}
interface IStickyFooter extends IStickyFooterButtons {
	stickyFooterScheduleButton?: Maybe<Button>;
}

export interface IFooter {
	/**
	 * Application footer data, containing the information about the brand and the navigation links
	 *
	 * @type {App['footer']}
	 * @memberof IFooter
	 */
	footerData: App['footer'];
	/**
	 * Whether the footer should display the scheduling content or not
	 *
	 * @type {boolean}
	 * @memberof IFooter
	 */
	withScheduling?: boolean;
	/**
	 * breadcrumbsData to display breadcrumbs
	 *
	 * @type {boolean}
	 * @memberof IFooter
	 */
	breadcrumbsData?: Page;
	/**
	 * Sets the Footer background color to light if true, otherwise dark.
	 *
	 * @type {boolean}
	 * @memberof IFooter
	 */
	isLightFooter?: boolean;
	/**
	 * Whether the sticky footer on mobile should be hidden for a particular page.
	 *
	 * @type {boolean | null}
	 * @memberof IFooter
	 */
	disableStickyFooterOnMobile?: boolean | null;
	/**
	 * Whether the sticky footer on desktop should be hidden for a particular page.
	 *
	 * @type {boolean | null}
	 * @memberof IFooter
	 */
	disableStickyFooterOnDesktop?: boolean | null;
}

function SocialMediaLinks({
	socialNetworks,
	isLightFooter,
}: {
	socialNetworks?: Maybe<AdFooterSocialNetworksCollection>;
	isLightFooter?: boolean;
}) {
	if (!socialNetworks?.items?.length) return null;

	const socNetworkItems = socialNetworks?.items as Page[];

	return (
		<Box sx={footerSocialMediaLinksStyles} data-test-id="section_footer_social_media_links">
			<Typography variant="bodyMediumBold" color={isLightFooter ? 'text.primary' : 'text.light'}>
				Follow us
			</Typography>
			<Box display="flex" gap={4} justifyContent="center" alignItems="center">
				<SocialNetworkIcons socNetworkItems={socNetworkItems} />
			</Box>
		</Box>
	);
}

export function BackToTopArrow({ isFooterLite = false }: { isFooterLite?: boolean }) {
	return (
		<TAGLink href="#" title="Back to top" dataTestId="link_go_to_top_arrow" linkSx={backToTopLinkWrapperStyle}>
			<Box sx={backToTopBoxWrapperStyles(isFooterLite)}>
				<ExpandLessIcon fontSize="small" color="inherit" />
				<Box sx={visuallyHidden}>Back to </Box>
				<Typography
					variant="buttonLabel"
					fontSize="small"
					color={isFooterLite ? 'text.interactive' : 'text.light'}
				>
					Top
				</Typography>
			</Box>
		</TAGLink>
	);
}

function StickyFooterButtonsCollection({
	stickyFooterButtonsCollection,
	logoUrl,
	chatBotUrl,
}: Readonly<IStickyFooterButtons>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const dataTestDeviceIdString = isSmallScreen ? 'mobile' : 'desktop';

	if (!stickyFooterButtonsCollection?.items.length) {
		return null;
	}

	return (
		<Box sx={stickyDesktopFooterBtnsWrapperStyles} data-test-id={`sticky_footer_buttons_${dataTestDeviceIdString}`}>
			{stickyFooterButtonsCollection.items.map((button: Maybe<Button>) => {
				if (!button) {
					return null;
				}
				return button?.label?.includes('Chat') ? (
					<ChatBot
						key={`sticky_footer_${dataTestDeviceIdString}_chatbot`}
						url={chatBotUrl ?? ''}
						featureList={[]}
						isIconButton
						logoUrl={logoUrl ?? ''}
						stickyFooterButton={button}
					/>
				) : (
					<ContentfulButton
						key={`sticky_footer_button_${dataTestDeviceIdString}_${button.label ?? ''}`}
						dataTestId={`sticky_footer_button_${dataTestDeviceIdString}_${button.label ?? ''}`}
						{...button}
						isSmallScreen={isSmallScreen}
					/>
				);
			})}
		</Box>
	);
}

function StickyDesktopFooter({
	stickyFooterScheduleButton,
	stickyFooterButtonsCollection,
	logoUrl,
	chatBotUrl,
}: Readonly<IStickyFooter>) {
	const scheduleBtnLinkFromContentful = stickyFooterScheduleButton?.buttonLink ?? SCHEDULE_PATH;
	const [selectedState, setSelectedState] = useState<string>(scheduleBtnLinkFromContentful);
	const [scheduleBtnLink, setScheduleBtnLink] = useState<string>(stickyFooterScheduleButton?.buttonLink ?? '');
	const theme = useTheme();

	const handleStateChange = (event: SelectChangeEvent) => {
		const state = event.target.value;
		setSelectedState(state);
		const SELECTED_REASON = REASONS_FOR_VISIT.find((item) => item.value === state);
		const hasTrailingSlash = scheduleBtnLinkFromContentful.endsWith('/');
		const slashString = hasTrailingSlash ? '' : '/';
		const updatedScheduleBtnLink = SELECTED_REASON
			? `${scheduleBtnLinkFromContentful}${slashString}${SELECTED_REASON.path}/`
			: scheduleBtnLinkFromContentful;
		setScheduleBtnLink(updatedScheduleBtnLink);
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
		anchorOrigin: {
			vertical: 'top',
			horizontal: 'center',
		},
		transformOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
		style: {
			zIndex: 10_100,
		},
	} as MenuProps;

	return (
		<Box sx={stickyDesktopFooterWrapperStyles}>
			{stickyFooterScheduleButton && (
				<Box sx={stickyDesktopFooterBtnsWrapperStyles} data-test-id="sticky_footer_schedule_button_desktop_ctr">
					<FormControl sx={getReasonSelectStyles(theme)}>
						<Select
							displayEmpty
							data-test-id="sticky_footer_schedule_button_desktop_reason_select"
							value={selectedState ?? ''}
							onChange={handleStateChange}
							input={<OutlinedInput />}
							renderValue={(value) => {
								const item = REASONS_FOR_VISIT.find((item) => item.value === value);
								if (item) {
									return (
										<Box sx={reasonSelectedMenuItemStyles}>
											<TAGSvgIcon icon={item.icon} size={24} />
											<Typography variant="bodyMediumBook">{item.label}</Typography>
										</Box>
									);
								} else {
									return (
										<Box sx={reasonSelectedMenuItemStyles}>
											<Typography variant="bodyMediumBook">Reason for visit*</Typography>
										</Box>
									);
								}
							}}
							MenuProps={MenuProps}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							{REASONS_FOR_VISIT.map((reason) => {
								return (
									<MenuItem
										value={reason.value}
										key={reason.value}
										sx={reasonMenuItemStyles}
										data-test-id={reason.value}
									>
										<TAGSvgIcon icon={reason.icon} size={24} />
										<Typography variant="bodyMediumBook">{reason.label}</Typography>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<ContentfulButton
						dataTestId="sticky_footer_schedule_button_desktop"
						{...stickyFooterScheduleButton}
						buttonLink={scheduleBtnLink}
					/>
				</Box>
			)}
			<StickyFooterButtonsCollection
				stickyFooterButtonsCollection={stickyFooterButtonsCollection}
				logoUrl={logoUrl}
				chatBotUrl={chatBotUrl}
			/>
		</Box>
	);
}

function StickyMobileFooter({
	stickyFooterScheduleButton,
	stickyFooterButtonsCollection,
	logoUrl,
	chatBotUrl,
}: Readonly<IStickyFooter>) {
	return (
		<Box sx={stickyMobileFooterWrapperStyles(!!stickyFooterButtonsCollection?.items?.length)}>
			{stickyFooterScheduleButton && (
				<ContentfulButton
					dataTestId="sticky_footer_schedule_button_mobile"
					{...stickyFooterScheduleButton}
					isSmallScreen
				/>
			)}
			<StickyFooterButtonsCollection
				stickyFooterButtonsCollection={stickyFooterButtonsCollection}
				logoUrl={logoUrl}
				chatBotUrl={chatBotUrl}
			/>
		</Box>
	);
}

export default function Footer({
	footerData,
	withScheduling = false,
	breadcrumbsData,
	isLightFooter = false,
	disableStickyFooterOnMobile = false,
	disableStickyFooterOnDesktop = false,
}: Readonly<IFooter>) {
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

	const { config } = useAppContext();

	const chatBotUrl = config.features.chat.chatBotURL;

	return (
		<>
			<Box component="footer" data-test-id="section_footer" sx={getWrapperStyles(isLightFooter)}>
				{!withScheduling && (
					<Grid container sx={breadCrumbsAndTopBtnContainerStyles}>
						{breadcrumbsData?.breadcrumbsCollection && (
							<Grid item sx={breadCrumbsContainerStyles}>
								<BreadCrumbs breadcrumbsData={breadcrumbsData} isFooterLite={isLightFooter} />
							</Grid>
						)}

						<BackToTopArrow isFooterLite={isLightFooter} />
					</Grid>
				)}

				<Container sx={getContentWrapperStyles} maxWidth={false}>
					<FooterNavigationContent
						isSmallDevice={isSmallDevice}
						withScheduling={withScheduling}
						navigationGroups={footerData?.navigationGroupsCollection}
						isLightFooter={isLightFooter}
					/>
					{withScheduling && !!footerData?.footerPolicyGroupCollection?.items?.length ? (
						<FooterPolicy policyGroup={footerData?.footerPolicyGroupCollection} />
					) : null}
					{!withScheduling ? (
						<SocialMediaLinks
							socialNetworks={footerData?.socialNetworksCollection}
							isLightFooter={isLightFooter}
						/>
					) : null}
					{!withScheduling && footerData?.contactNumber ? (
						<TAGLink
							href={`tel:${footerData.contactNumber || '#'}`}
							linkSx={getContactInfoWrapperStyles}
							dataTestId="link_contact_number"
							analyticsContext={{ clickPosition: 'footer' } as AnalyticsContext}
						>
							<Icon
								sx={{
									display: 'inline-flex',
									color: isLightFooter ? 'text.interactive' : 'text.light',
								}}
							>
								<PhoneIphoneIcon />
							</Icon>
							<Typography
								variant="header4"
								component={'span'}
								color={isLightFooter ? 'text.interactive' : 'text.light'}
								title="Contact Number"
							>
								{footerData.contactNumber}
							</Typography>
						</TAGLink>
					) : null}

					<Typography
						variant="bodyMediumBook"
						color={isLightFooter ? 'text.primary' : 'text.light'}
						sx={copyrightStyles}
						data-test-id="text_footer_copyright"
					>
						{footerData?.copyright || ''}
					</Typography>

					{footerData?.about?.json && (
						<Container
							data-test-id="section_footer_about"
							sx={{ ...getAboutWrapperStyles, flexDirection: 'column', gap: 0 }}
						>
							<RichText
								content={footerData.about.json as RichTextContent}
								docProps={{
									color: isLightFooter ? 'text.primary' : 'text.light',
									variant: 'captionMedium',
									textAlign: 'center',
								}}
							/>
						</Container>
					)}
				</Container>
			</Box>

			{isSmallDevice && footerData?.useMobileStickyFooter && !disableStickyFooterOnMobile && (
				<StickyMobileFooter
					stickyFooterScheduleButton={footerData.stickyFooterScheduleButton}
					stickyFooterButtonsCollection={footerData.stickyFooterButtonsCollection}
					logoUrl={footerData.logo?.url}
					chatBotUrl={chatBotUrl}
				/>
			)}

			{!isSmallDevice && footerData?.useDesktopStickyFooter && !disableStickyFooterOnDesktop && (
				<StickyDesktopFooter
					stickyFooterScheduleButton={footerData.stickyFooterScheduleButton}
					stickyFooterButtonsCollection={footerData.stickyFooterButtonsCollection}
					logoUrl={footerData.logo?.url}
					chatBotUrl={chatBotUrl}
				/>
			)}
		</>
	);
}
